import get from "lodash/get";

export const getSystemFieldList = (collectionName: string): string[] => {
  return get(systemFieldsList, collectionName);
};

const systemFieldsList = {
  directus_users: [
    "first_name",
    "last_name",
    "email",
    "password",
    "avatar",
    "location",
    "title",
    "description",
    "tags",
    "preferences_divider",
    "language",
    "theme",
    "tfa_secret",
    "email_notifications",
    "admin_divider",
    "token",
    "id",
    "last_page",
    "last_access",
    "provider",
    "external_identifier",
    "auth_data",
    "status",
    "role",
  ],
};

