import { ref, watch, Ref, computed } from "vue";
import { FieldInfoInterface } from "~/entities/field";
import { IItem } from "~/entities/item";
import { ItemValidationErrors } from "../../model/interfaces";
import { validateRefactoredItem } from "../../model/validate-item";

export function useRefactoredItemValidation(
  item: Ref<IItem>,
  fields: Ref<FieldInfoInterface[]>,
) {
  const isValid = ref<boolean>(true);
  const errors = ref<ItemValidationErrors>({});

  watch(
    () => [item.value, fields.value],
    ([newItem, newFields]) => {
      const validationErrors = validateRefactoredItem(
        newItem as IItem,
        newFields as FieldInfoInterface[],
      );

      errors.value = validationErrors;
      isValid.value = !Object.keys(validationErrors).length;
    },
    {
      immediate: true,
      deep: true,
    },
  );

  return {
    isValid: computed(() => isValid.value),
    errors: computed(() => errors.value),
  };
}

