<template>
  <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
    <div class="intro-y box lg:mt-5">
      <div
        class="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400"
      >
        <h2 class="mr-auto text-base font-medium">{{ $t("personal_information") }}</h2>
      </div>

      <div class="p-5">
        <div class="flex flex-col xl:flex-row">
          <form class="flex-1 mt-6 xl:mt-0" @submit.stop.prevent="onUpdateUser">
            <FieldsFormDisplay
              :collection="collection!"
              :item="props.profileData"
              :fields="systemFields"
              v-on="systemFieldsListeners"
            />

            <FieldsFormDisplay
              :collection="collection!"
              :item="props.profileData"
              :fields="customFields"
              v-on="customFieldsListeners"
            />

            <Button
              variant="primary"
              type="submit"
              :disabled="!canSubmitForm"
              class="w-24 mt-3"
              v-tooltip.top="{
                value: $t('error.not_enough_permission.to_update'),
                disabled: havePermissionForUpdate,
                context: {
                  right: true,
                },
              }"
              >{{ $t("save") }}
            </Button>

            <ValidationErrorDetailsTippyButton
              :isShown="!canSubmitForm"
              :errors="validationErrors"
              class="ml-3"
            />
          </form>

          <div class="mx-auto w-52 xl:mr-0 xl:ml-6">
            <div
              class="p-5 border-2 border-dashed rounded-md shadow-sm border-slate-200/60 dark:border-darkmode-400"
            >
              <div class="relative h-40 mx-auto cursor-pointer image-fit zoom-in">
                <img
                  v-if="!!props.profileData.getDataProperty('avatar')"
                  :alt="`${props.profileData.getDataProperty(
                    'first_name',
                  )} ${props.profileData.getDataProperty('first_name')}`"
                  :src="
                    getDatastudioAssetUrl(
                      props.profileData.getDataProperty('avatar') as string,
                    )
                  "
                  class="rounded-md"
                />
                <!-- <div
                  class="cursor-pointer absolute top-0 right-0 flex items-center justify-center w-5 h-5 -mt-2 -mr-2 text-white rounded-full bg-danger"
                >
                  <Lucide icon="X" class="w-4 h-4" />
                </div> -->
              </div>
              <!-- 
              <div class="relative mx-auto mt-5">
                <Button
                  variant="primary"
                  class="w-full [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90"
                >
                  {{ $t("change_photo") }}
                </Button>

                <FormInput
                  type="file"
                  class="absolute top-0 left-0 w-full h-full opacity-0 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90"
                />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_IItemMeta = {
  [key: string]: unknown;
} | null
type _VTI_TYPE_ItemID = string | number
interface _VTI_TYPE_UserInterface {
  readonly id: _VTI_TYPE_ItemID;
  readonly data: _VTI_TYPE_T;
  readonly meta: _VTI_TYPE_IItemMeta;
  setDataProperty(propertyOrPath: string, data: unknown): IItem;
  getDataProperty(propertyOrPath: string): unknown;
  setMetaProperty(propertyOrPath: string, data: unknown): IItem;
  getMetaProperty(propertyOrPath: string): unknown;
  get role(): RoleInterface;
}
interface Props {
    profileData: _VTI_TYPE_UserInterface;
  }
  import { useI18n } from '#i18n'
  import { computed, onBeforeUnmount, watch } from 'vue'
  import { UsersGatewayInterface } from '~/entities/user'
  import Button from '~/shared/ui/Button'
  import { getDatastudioAssetUrl } from '~/api/files/utils/url'
  import { iocContainer } from '~/inversify.config'
  import { INJECT_SYMBOLS } from '~/service/inversion-of-control/inject-symbols'
  import { toaster } from '~/service/toaster'
  import { getSystemFieldList, FieldsFormDisplay, useFieldsEventListeners, useCollectionFieldsExcluding, useCollectionFieldsIncluding, FieldInfoInterface } from '~/entities/field'
  import { useCollecitonsStore } from '~/stores/collections'
  import { usePermissionsStore } from '~/stores/permissions'
  import { PermissionAction } from '~/api/permissions/entity/PermissionInterface'
  import { useRefactoredItemValidation } from '~/entities/item-validation'
  import { ValidationErrorDetailsTippyButton } from '~/entities/item-validation'
  import { ItemReactionsController } from '~/entities/system-reactions'
  import { AppEventBus, AppEvents } from '~/shared/lib/app-event-bus'
  import ItemInterface from '~/api/items/entities/ItemInterface'
  
  const props = defineProps<Props>();
  const emit = defineEmits<{
    "update:user": [payload: UserInterface];
  }>();
  const collectionsStore = useCollecitonsStore();
  const permissionsStore = usePermissionsStore();
  const { t } = useI18n();
  const collection = computed(() => collectionsStore.getCollection("directus_users"));
  const havePermissionForUpdate = computed<boolean>(() =>
    permissionsStore.hasCollectionPermission("directus_users", PermissionAction.UPDATE),
  );
  const computedProfileData = computed(() => {
    return props.profileData;
  });
  const { fields: systemFields } = useCollectionFieldsIncluding(
    "directus_users",
    computedProfileData,
    ["first_name", "last_name", "email"],
  );
  const { fieldsEventListeners: systemFieldsListeners } = useFieldsEventListeners(
    systemFields,
    computedProfileData,
  );
  const { fields: customFields } = useCollectionFieldsExcluding(
    "directus_users",
    computedProfileData,
    getSystemFieldList("directus_users"),
  );
  const { fieldsEventListeners: customFieldsListeners } = useFieldsEventListeners(
    customFields,
    computedProfileData,
  );
  async function onUpdateUser(): Promise<void> {
    try {
      const userApi = iocContainer.get<UsersGatewayInterface>(
        INJECT_SYMBOLS.UsersGatewayInterface,
      );
      const updatedUser = await userApi.updateUser(
        props.profileData.id as string,
        props.profileData,
      );
      toaster().success(t("user_updated"));
      emit("update:user", updatedUser);
    } catch (err) {
      toaster().error(`${t("error_user_update")}: ${err!.message}`);
    }
  }
  const availableFieldsInfo = computed<FieldInfoInterface[]>(() =>
    Object.values(systemFields.value)
      .map((field) => field.info)
      .concat(Object.values(customFields.value).map((field) => field.info)),
  );
  const { isValid, errors: validationErrors } = useRefactoredItemValidation(
    computed(() => props.profileData),
    availableFieldsInfo,
  );
  const canSubmitForm = computed<boolean>(
    () => !!isValid.value && !!havePermissionForUpdate.value,
  );
  const eventBus = iocContainer.get<AppEventBus>(INJECT_SYMBOLS.AppEventBus);
  const itemReactionsController = iocContainer.get<ItemReactionsController>(
    INJECT_SYMBOLS.ItemReactionsController,
  );
  onBeforeUnmount(() => {
    itemReactionsController.destroy();
  });
  watch(
    () => [props.profileData, availableFieldsInfo.value],
    ([newItem, newFields]) => {
      if (!newItem) return;
      itemReactionsController.setItem(
        props.profileData as UserInterface as unknown as ItemInterface,
      );
      itemReactionsController.setFields(
        availableFieldsInfo.value as FieldInfoInterface[],
      );
      eventBus.dispatch({
        event: AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW,
        payload: {
          collectionName: "directus_users",
        },
      });
    },
    {
      immediate: true,
    },
  );
</script>

