import { ComputedRef, computed } from "vue";
import { Field } from "~/entities/field";
import { IItem } from "~/entities/item";
import { useCollectionFields } from "./useCollectionFields";

export const useCollectionFieldsExcluding = (
  collectionName: string,
  item: ComputedRef<IItem | undefined>,
  excludedFieldNames: string[],
) => {
  const { fields: collectionFields } = useCollectionFields(collectionName, item);

  const fields = computed<Record<string, Field>>(() => {
    const result: Record<string, Field> = {};

    const fieldList = Object.keys(collectionFields.value);

    for (const fieldName of fieldList) {
      if (!excludedFieldNames.includes(fieldName)) {
        result[fieldName] = collectionFields.value[fieldName];
      }
    }
    return result;
  });

  return { fields };
};

